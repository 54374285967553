@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
:root {
  --primary-color: #633cff;
  --secondary-color: black;
  --bg-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1 {
  font-family: "Lato", monospace;
  font-weight: 900;
}
p {
  font-family: "Lato", monospace;
  font-weight: 400;
}
body {
  font-family: "Lato", monospace;
  font-weight: 500;
}
body::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #633cff;
  background: var(--primary-color);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

nav {
  max-width: 76%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-links li {
  list-style: none;
  margin-left: 40px;
}
.active {
  color: var(--primary-color);
}
.non-active {
  color: black;
}
.nav-links a {
  text-decoration: none;
  font-size: 1.3rem;
  font-family: sans-serif;
  transition: 0.3s ease-in-out;
  font-family: "Lato";
  font-weight: 400;
}
.nav-links a:hover {
  color: var(--primary-color);
}
.logo {
  width: 110px;
}
@media screen and (max-width: 960px) {
  nav {
    max-width: 100%;
  }
  .nav-links li {
    margin-left: 20px;
    margin-right: 10px;
  }
  .logo {
    width: 80px;
    margin-left: 20px;
  }
  .nav-links a {
    font-size: 1.3rem;
  }
}

.main {
  max-width: 75%;
  margin: auto;
}
.heroflex {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
.textflex {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}
.textflex h1 {
  font-size: 5.5rem;
  font-family: sans-serif;
  background-image: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0.55) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-family: "Helvetica", monospace;
  font-weight: 700;
}
.textflex p {
  font-size: 1.5rem;
  margin-top: 25px;
}
.buttons {
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.btn-download {
  background: var(--primary-color);
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  color: white;
  margin-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Lato";
  font-weight: 700;
}
.buttons a {
  text-decoration: none;
  color: var(--secondary-color);
  font-family: sans-serif;
  font-size: 1.2rem;
  font-family: "Lato";
  font-weight: 700;
}
.image img {
  width: 500px;
}
@media screen and (max-width: 960px) {
  .main {
    max-width: 100%;
  }
  .heroflex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
    margin: auto;
  }
  .textflex h1 {
    font-size: 5rem;
  }
  .textflex p {
    font-size: 1.3rem;
  }
  .image img {
    max-width: 80vw;
    margin: auto;
    margin-top: 50px;
  }
}

.full {
  background: var(--primary-color);
  color: white;
  margin-top: 100px;
}
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  margin-bottom: 30px;
  max-width: 75%;
  margin: auto;
  padding-bottom: 20px;
}
.loging {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.hol img {
  width: 100px;
}
.shit {
  max-width: 300px;
  font-family: "Lato", monospace;
  margin-top: 20px;
  font-size: 1rem;
}
.linkss {
  display: flex;
  justify-content: space-evenly;
  min-width: 50%;
  margin-top: 40px;
  align-items: center;
}
.fist {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.fist a {
  text-decoration: none;
  font-family: "Lato";
  font-size: 0.9rem;
  margin-top: 8px;
  color: white;
}
.soojao h2 {
  font-size: 1.2rem;
}
.social {
  min-width: 12%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .full {
    display: none;
  }
}

.about {
  max-width: 75%;
  margin: auto;
}
.about h1 {
  background-image: linear-gradient(
    180deg,
    #633cff 0%,
    rgba(99, 60, 255, 0.55) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 3.2rem;
  font-weight: 600;
  margin-top: 50px;
}
.aboutflex {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
.width {
  min-width: 420px;
}
.aboutext {
  display: flex;
  flex-direction: column;
}
.aboutext p {
  width: 500px;
  font-family: sans-serif;
  font-size: 1.2rem;
  margin-top: 25px;
  line-height: 27px;
  font-family: "Lato", monospace;
  font-weight: 500;
}
.aboutimage img {
  width: 350px;
}
.reverse {
  flex-direction: row-reverse;
}
.exc-image img {
  width: 300px;
}
.hlo {
  width: 200px;
}
.exc2 {
  margin-top: 100px;
}

@media screen and (max-width: 960px) {
  .about {
    max-width: 100%;
    margin: auto;
  }
  .about h1 {
    font-size: 2.9rem;
  }
  .aboutext {
    max-width: 85vw;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  .aboutflex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    margin: auto;
    margin-top: 100px;
  }
  .aboutext p {
    max-width: 85vw;
    margin: auto;
    margin-top: 40px;
  }
  .aboutimage img {
    max-width: 85vw;
    margin: auto;
    margin-top: 40px;
  }
  .exc-image img {
    max-width: 85vw;
    margin: auto;
    margin-top: 40px;
  }
  .reverse {
    flex-direction: column;
  }
  .width {
    max-width: 80vw;
    margin: auto;
  }
}

