@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
:root {
  --primary-color: #633cff;
  --secondary-color: black;
  --bg-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1 {
  font-family: "Lato", monospace;
  font-weight: 900;
}
p {
  font-family: "Lato", monospace;
  font-weight: 400;
}
body {
  font-family: "Lato", monospace;
  font-weight: 500;
}
body::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--primary-color);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
