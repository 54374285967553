nav {
  max-width: 76%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-links li {
  list-style: none;
  margin-left: 40px;
}
.active {
  color: var(--primary-color);
}
.non-active {
  color: black;
}
.nav-links a {
  text-decoration: none;
  font-size: 1.3rem;
  font-family: sans-serif;
  transition: 0.3s ease-in-out;
  font-family: "Lato";
  font-weight: 400;
}
.nav-links a:hover {
  color: var(--primary-color);
}
.logo {
  width: 110px;
}
@media screen and (max-width: 960px) {
  nav {
    max-width: 100%;
  }
  .nav-links li {
    margin-left: 20px;
    margin-right: 10px;
  }
  .logo {
    width: 80px;
    margin-left: 20px;
  }
  .nav-links a {
    font-size: 1.3rem;
  }
}
