.about {
  max-width: 75%;
  margin: auto;
}
.about h1 {
  background-image: linear-gradient(
    180deg,
    #633cff 0%,
    rgba(99, 60, 255, 0.55) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 3.2rem;
  font-weight: 600;
  margin-top: 50px;
}
.aboutflex {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
.width {
  min-width: 420px;
}
.aboutext {
  display: flex;
  flex-direction: column;
}
.aboutext p {
  width: 500px;
  font-family: sans-serif;
  font-size: 1.2rem;
  margin-top: 25px;
  line-height: 27px;
  font-family: "Lato", monospace;
  font-weight: 500;
}
.aboutimage img {
  width: 350px;
}
.reverse {
  flex-direction: row-reverse;
}
.exc-image img {
  width: 300px;
}
.hlo {
  width: 200px;
}
.exc2 {
  margin-top: 100px;
}

@media screen and (max-width: 960px) {
  .about {
    max-width: 100%;
    margin: auto;
  }
  .about h1 {
    font-size: 2.9rem;
  }
  .aboutext {
    max-width: 85vw;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  .aboutflex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    margin: auto;
    margin-top: 100px;
  }
  .aboutext p {
    max-width: 85vw;
    margin: auto;
    margin-top: 40px;
  }
  .aboutimage img {
    max-width: 85vw;
    margin: auto;
    margin-top: 40px;
  }
  .exc-image img {
    max-width: 85vw;
    margin: auto;
    margin-top: 40px;
  }
  .reverse {
    flex-direction: column;
  }
  .width {
    max-width: 80vw;
    margin: auto;
  }
}
